/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for DistributorApi services
 */
@Injectable({
  providedIn: "root",
})
export class DistributorApiConfiguration {
  rootUrl: string = "https://distributorapi.acc.ejgallo.com";
  constructor() {
    if (location.origin.includes("prd")) {
      this.rootUrl = "https://distributorapi.prd.ejgallo.com";
    } else if (location.origin.includes("acc")) {
      this.rootUrl = "https://distributorapi.acc.ejgallo.com";
    } else if (location.origin.includes("dev")) {
      this.rootUrl = "https://distributorapi.dev.ejgallo.com";
    } else if (location.origin.includes("products.ejgallo.com")) {
      this.rootUrl = "https://distributorapi.prd.ejgallo.com";
      // MK added to allow DNS move of products.ejgallo.com
    }
  }
}

export interface DistributorApiConfigurationInterface {
  rootUrl?: string;
}
