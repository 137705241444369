/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for UserDistribApi services
 */
@Injectable({
  providedIn: 'root',
})
export class UserDistribApiConfiguration {
    rootUrl: string = 'https://productsacc.ejgallo.com:3012/sas/api/v1';
}

export interface UserDistribApiConfigurationInterface {
  rootUrl?: string;
}
