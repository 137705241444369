import { Injectable } from "@angular/core";

@Injectable()
export class EnvironmentService {
  constructor() {}

  static get environment() {
    let redirectUri = "http://localhost:4200/";
    let clientId = "c1088d15-1772-4060-9bed-0cd9df12d97e";
    let tenantId = "3fb1deee-7df8-4d82-9cec-844125522b6f";
    let aadDomain = "est1933a";
    let production = false;

    if (
      location.origin.includes("prd") ||
      location.origin.includes("products.ejgallo.com")
    ) {
      // MK added products.ejgallo.com to enable permanent DNS change
      // once done redir URL can be changed to products.ejgallo.com
      redirectUri = "https://products.prd.ejgallo.com/";
      clientId = "1af12933-0fec-4ef6-a867-731e45b81002";
      tenantId = "a7f53388-893d-49a4-94df-957f5ced1518";
      aadDomain = "ejgallo";
      production = true;
    } else if (location.origin.includes("acc")) {
      redirectUri = "https://products.acc.ejgallo.com/";
    } else if (location.origin.includes("dev")) {
      redirectUri = "https://products.dev.ejgallo.com/";
    }

    return {
      production,
      clientId,
      redirectUri,
      profileEndpoint: "https://graph.microsoft.com/v1.0/me",
      authority: `https://login.microsoftonline.com/${tenantId}`,
      scopes: ["user.read", "openid", "profile"],
      protectedResources: new Map<string, Array<string>>()
        .set("https://graph.microsoft.com/v1.0/me", ["user.read"])
        .set("https://graph.microsoft.com/v1.0/users", ["user.read"]),
      myMSGraphProperty: `${aadDomain}_GalloUser`,
      myMSGraph: "https://graph.microsoft.com/v1.0/me?$select=",
    };
  }
}
