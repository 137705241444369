/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { DistributorApiConfiguration as __Configuration } from '../distributor-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class ApiService extends __BaseService {
  static readonly getCountriesPath = '/api/v1/country';
  static readonly getStatesPath = '/api/v1/state';
  static readonly getBillToDistributorsPath = '/api/v1/billto';
  static readonly getShipToDistributorsPath = '/api/v1/shipto';
  static readonly getPerfPath = '/perf';
  static readonly getHeartbeatPath = '/heartbeat';
  static readonly aboutPath = '/about';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * # Distributor service country list
   * Returns a list of countries for Gallo distributors.
   */
  getCountriesResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/country`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * # Distributor service country list
   * Returns a list of countries for Gallo distributors.
   */
  getCountries(): __Observable<null> {
    return this.getCountriesResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * # Distributor service state list
   * Returns a list of states for a given country for Gallo distributors.
   *
   * Pass parameter countryid to filter by country. If countryid not passed will return all states for all countries which have states or provinces
   * @param countryid 2 letter country id to filter states by
   */
  getStatesResponse(countryid?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (countryid != null) __params = __params.set('countryid', countryid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/state`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * # Distributor service state list
   * Returns a list of states for a given country for Gallo distributors.
   *
   * Pass parameter countryid to filter by country. If countryid not passed will return all states for all countries which have states or provinces
   * @param countryid 2 letter country id to filter states by
   */
  getStates(countryid?: string): __Observable<null> {
    return this.getStatesResponse(countryid).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * # Distributor BillTo service
   * Returns a list of billTo distributors.
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered to a single distributor (note: dist parameter is validated to ensure the user has access to that distributor)
   * @param params The `ApiService.GetBillToDistributorsParams` containing the following parameters:
   *
   * - `stateid`: Optional filter by state (or province) ID
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `dist`: Distributor billTo id
   *
   * - `countryid`: Optional filter by Country ID
   */
  getBillToDistributorsResponse(params: ApiService.GetBillToDistributorsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.stateid != null) __params = __params.set('stateid', params.stateid.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.pageno != null) __params = __params.set('pageno', params.pageno.toString());
    if (params.dist != null) __params = __params.set('dist', params.dist.toString());
    if (params.countryid != null) __params = __params.set('countryid', params.countryid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/billto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * # Distributor BillTo service
   * Returns a list of billTo distributors.
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered to a single distributor (note: dist parameter is validated to ensure the user has access to that distributor)
   * @param params The `ApiService.GetBillToDistributorsParams` containing the following parameters:
   *
   * - `stateid`: Optional filter by state (or province) ID
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `dist`: Distributor billTo id
   *
   * - `countryid`: Optional filter by Country ID
   */
  getBillToDistributors(params: ApiService.GetBillToDistributorsParams): __Observable<null> {
    return this.getBillToDistributorsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * # Distributor ShipTo service
   * Returns a list of ShiptTo distributors.
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered to a single distributor (note: dist parameter is validated to ensure the user has access to that distributor)
   * @param params The `ApiService.GetShipToDistributorsParams` containing the following parameters:
   *
   * - `stateid`: Optional filter by state (or province) ID
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `dist`: Distributor shipTo id
   *
   * - `countryid`: Optional filter by Country ID
   */
  getShipToDistributorsResponse(params: ApiService.GetShipToDistributorsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.stateid != null) __params = __params.set('stateid', params.stateid.toString());
    if (params.pagesize != null) __params = __params.set('pagesize', params.pagesize.toString());
    if (params.pageno != null) __params = __params.set('pageno', params.pageno.toString());
    if (params.dist != null) __params = __params.set('dist', params.dist.toString());
    if (params.countryid != null) __params = __params.set('countryid', params.countryid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/shipto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * # Distributor ShipTo service
   * Returns a list of ShiptTo distributors.
   *
   * If optional dist URI parameter is passed and valid, the list will be filtered to a single distributor (note: dist parameter is validated to ensure the user has access to that distributor)
   * @param params The `ApiService.GetShipToDistributorsParams` containing the following parameters:
   *
   * - `stateid`: Optional filter by state (or province) ID
   *
   * - `pagesize`: Number of products to return per page
   *
   * - `pageno`: Page number in a paged result set - pageno starts at 1
   *
   * - `dist`: Distributor shipTo id
   *
   * - `countryid`: Optional filter by Country ID
   */
  getShipToDistributors(params: ApiService.GetShipToDistributorsParams): __Observable<null> {
    return this.getShipToDistributorsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns server/service metrics
   */
  getPerfResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/perf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Returns server/service metrics
   */
  getPerf(): __Observable<null> {
    return this.getPerfResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Hearbeat returns status of service and ability to query Hana EDW
   */
  getHeartbeatResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/heartbeat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Hearbeat returns status of service and ability to query Hana EDW
   */
  getHeartbeat(): __Observable<null> {
    return this.getHeartbeatResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns basic information about the service and server
   */
  aboutResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/about`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Returns basic information about the service and server
   */
  about(): __Observable<null> {
    return this.aboutResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ApiService {

  /**
   * Parameters for getBillToDistributors
   */
  export interface GetBillToDistributorsParams {

    /**
     * Optional filter by state (or province) ID
     */
    stateid?: string;

    /**
     * Number of products to return per page
     */
    pagesize?: number;

    /**
     * Page number in a paged result set - pageno starts at 1
     */
    pageno?: number;

    /**
     * Distributor billTo id
     */
    dist?: number;

    /**
     * Optional filter by Country ID
     */
    countryid?: string;
  }

  /**
   * Parameters for getShipToDistributors
   */
  export interface GetShipToDistributorsParams {

    /**
     * Optional filter by state (or province) ID
     */
    stateid?: string;

    /**
     * Number of products to return per page
     */
    pagesize?: number;

    /**
     * Page number in a paged result set - pageno starts at 1
     */
    pageno?: number;

    /**
     * Distributor shipTo id
     */
    dist?: number;

    /**
     * Optional filter by Country ID
     */
    countryid?: string;
  }
}

export { ApiService }
