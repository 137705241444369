import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RestService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  public post(url, data): Promise<any> {
    const responseType = 'blob';
    return this._httpClient.post(url, data, {responseType}).toPromise();
  }

  public get(url, options?): Promise<any> {
    if (url.includes('format=')) {
        if (!options) {
            options = {};
        }
        options.responseType = 'blob';
    }
    return this._httpClient.get(url, options).toPromise();
  }

}
