import {Injectable} from '@angular/core';
import { UserResponse } from '../api/generated/userdistrib/models';


@Injectable({
  providedIn: 'root',
})
export class StateManagementService {

  public user: UserResponse;
  public isWineryUser: boolean = false;
  public selectedDistributor: any;
  public version: string = '0.0';
  public galloEnv: string = '';
  public externalServiceUrl = 'https://gateway.fbwineapps.com/gateway-external-services/ContentHubAsset/get_gch_content?gateway_token=f85f803c-f7b3-4368-b751-3bddeaae550a';

  constructor( ) {}

  public onDistributorSet(): void {}

  public onUserSet(): void {}

  public getEnvLabel() {
    if (this.isPrd()) {
      return 'PROD';
    } else if (this.isAcc()) {
      return 'ACC';
    } else {
      return 'DEV';
    }
  }

  public isDev() {
    return this.galloEnv === 'DEVELOPMENT';
  }

  public isAcc() {
    return this.galloEnv === 'ACCEPTANCE';
  }

  public isPrd() {
    return this.galloEnv === 'PRODUCTION';
  }

  public setUser(user: UserResponse): void {
    this.user = user;
    this.isWineryUser = user && ['winery', 'sales'].includes(user.userType);
    this.onUserSet();
  }
}
