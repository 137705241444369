import { Component, OnInit } from "@angular/core";
import { ApiService as UserDistributorApiService } from "../../app/api/generated/userdistrib/services/api.service";
import { ApiService as ProductApiService } from "../api/generated/product/services";
// import { ProductList, Product, FilterList } from '../api/generated/product/models';
import { StateManagementService } from "../lib/state-management.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RestService } from "../lib/rest.service";
import { Helpers } from "../lib/helpers";
import { Lightbox, LightboxConfig } from "ngx-lightbox";
import { environment } from "src/environments/environment";

export enum ETargetFilterBy {
  SKU = "skuid",
  UPC = "upcid",
  DistSKU = "distskuid",
}

export interface ITargetFilterBy {
  key: ETargetFilterBy;
  value: string;
}

export enum ERegionalFilter {
  DOMESTIC = "DOMESTIC",
  INTERNATIONAL = "INTERNATIONAL",
}

export interface IFilterOption {
  key: string;
  name: string;
}

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public pageLoading: boolean = false;
  public productListLoading: boolean = false;
  public isExporting: boolean = false;
  public isLoadingProduct: boolean = false;

  public brands: IFilterOption[] = [];
  public flavors: IFilterOption[] = [];
  public sizes: IFilterOption[] = [];
  public appellations: IFilterOption[] = [];

  public filterList: any[] = [];
  public isLoadingFilterList: boolean = true;

  public products: any[] = [];
  public cacheLookup: any[] = [];
  public imageAlbum: any[] = [];

  public regionalFilter: ERegionalFilter = ERegionalFilter.DOMESTIC;
  public showRegionalFilter: boolean = true;

  public get selectedProduct(): any {
    return this._selectedProduct;
  }

  public set selectedProduct(product: any) {
    // Only works if the provided SKU is 9 characters long
    if (product && product.sku.length === 9) {
      product["SKU_Version"] = product["sku"].substring(5, 7);
      // product['SKU_InventoryCode'] = product['SKU'].substring(7); // Not being displayed currently
    }
    this._selectedProduct = product;
  }

  private _selectedProduct: any;

  public shouldGetNextPage: boolean = true;

  // Params
  public pageno: number = 1;
  public pagesize: number = 50;
  public brandid: string = "";
  public flavorid: string = "";
  public sizeid: string = "";
  public appellationid: string = "";
  public status: "A" | "I" = "A";
  public userType: string = "";

  // So it can be used in the HTML
  public ETargetFilterBy = ETargetFilterBy;

  public targetFilterBy: ITargetFilterBy = {
    key: ETargetFilterBy.UPC,
    value: "",
  };

  public get targetFilterByLabel(): string {
    switch (this.targetFilterBy.key) {
      case ETargetFilterBy.DistSKU:
        return "Distributor SKU";
      case ETargetFilterBy.SKU:
        return "SKU";
      case ETargetFilterBy.UPC:
        return "UPC";
    }
  }

  public isDirty: boolean = true;

  private readonly downloadAnchorTag: HTMLAnchorElement;

  private static obfuscate(string: string | number): string {
    const obfuscatedString: string = btoa(string + "");
    return obfuscatedString.replace(/=/g, "");
  }

  private static deobfuscate(string: string) {
    return string ? atob(string) : null;
  }

  constructor(
    public stateManagementService: StateManagementService,
    private productApiService: ProductApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private lightbox: Lightbox,
    private lightboxConfig: LightboxConfig,
    private userDistributorApiService: UserDistributorApiService
  ) {
    this.stateManagementService.onDistributorSet =
      this.onDistributorSet.bind(this);
    this.stateManagementService.onUserSet = this.onUserSet.bind(this);

    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams) this.setStateFromQueryParams(queryParams);

    this.downloadAnchorTag = document.createElement("a");
    this.downloadAnchorTag.style.display = "none";
    document.body.appendChild(this.downloadAnchorTag);

    this.lightboxConfig.wrapAround = true;
  }

  async ngOnInit() {
    console.log("main on init");
    this.pageLoading = true;

    this.userType = this.stateManagementService.user.userType;
    console.log(this.userType, "mainusertype");
  }

  /**
   * Marks the filters and dirty and immediately gets filtered products
   */
  public onDistributorSet(shouldResetFilters = true): void {
    this.showRegionalFilter = !(
      this.stateManagementService.selectedDistributor &&
      this.stateManagementService.selectedDistributor.id
    );
    if (shouldResetFilters) this.resetFilters(false);
    this.isDirty = true;
    this.searchProducts();
    this.loadFilters();
  }

  public onUserSet(): void {
    try {
      // We want to wait for the Distributor to be selected before loading products and filters
      if (this.stateManagementService.isWineryUser) {
        this.searchProducts();
        this.loadFilters();
      }
      this.pageLoading = false; // mk added 11-19-24 to stop spinner for distribs
      this.userType = this.stateManagementService.user.userType;
      console.log("onuserset", this.userType);
    } catch (error) {
      console.log("ERROR -  ngOnInit(): ", error);
    }
  }

  public async onFilterChange(): Promise<void> {
    this.isDirty = true;
    this.filterFilters();
  }

  public onRegionFilterChange(): void {
    // resetFilters has to be called before loadFilters
    this.resetFilters(false, null);
    this.loadFilters();
  }

  public resetFilters(
    shouldFilterAndSearch: boolean = true,
    targetFilterByKey: ETargetFilterBy = ETargetFilterBy.UPC
  ): void {
    this.targetFilterBy.value = "";
    this.targetFilterBy.key = targetFilterByKey;

    this.brandid = "";
    this.flavorid = "";
    this.sizeid = "";
    this.appellationid = "";

    if (!this.stateManagementService.selectedDistributor) {
      this.onDistributorSet(false);
    }

    if (shouldFilterAndSearch) {
      this.onFilterChange();
      this.searchProducts();
    }
  }

  public setSelectedFilterBy(targetFilterByKey: ETargetFilterBy): void {
    if (targetFilterByKey === ETargetFilterBy.SKU) {
      this.resetFilters(false, targetFilterByKey);
    } else {
      this.targetFilterBy.value = "";
      this.targetFilterBy.key = targetFilterByKey;
    }
  }

  // TODO: should this go to 2 decimals?
  public getWeightFromDimensions(
    dimension: string,
    selectedProduct: any
  ): string {
    const findCsDimension = selectedProduct.dimensions.find(
      (dim) => dim.UOM === dimension
    );
    if (findCsDimension && findCsDimension.weight) {
      return `${findCsDimension.weight.toFixed(2)} lbs.`;
    } else {
      return "-";
    }
  }

  public async searchProducts(): Promise<void> {
    this.productListLoading = true;

    // No data to get, no new filters to change the data set
    if (!this.shouldGetNextPage && !this.isDirty) return;

    // New set of filters reset values
    if (this.isDirty) {
      this.products = [];
      this.selectedProduct = null;
      this.pageno = 1;
      this.pageLoading = true;
    }

    try {
      const params = this.buildGetProductParams();
      console.log("params", params);

      const products = (await this.productApiService
        .getProducts(params)
        .toPromise()) as any[];

      // If the array does haven't the same number of items requested it must be the end of the list
      this.shouldGetNextPage = this.pagesize === products.length;

      if (this.isDirty) {
        this.products = products;
        this.selectProduct(this.products[0]);
      } else {
        this.products.push.apply(this.products, products);
      }
    } catch (error) {
      // Time to make some assumptions
      if (this.isDirty) {
        // If no results are returned after using a new set of filter assume there are no products that fit the params
        this.products = [];

        // Normally `this.pageLoading = false` is set in `this.selectProduct` but if it error it wouldn't have gotten there
        this.pageLoading = false;
      } else {
        // If it already has some product, assume it is the end of the list, do nothing
      }
      // console.log('ERROR - filter:', error);
    }
    this.rewriteUrl();
    this.productListLoading = false;

    if (this.isDirty) {
      this.isDirty = false;
    }
  }

  public onScroll(): void {
    if (this.products.length) {
      this.pageno += 1;
      this.searchProducts();
    }
  }

  public open(src: string): void {
    const index = this.imageAlbum.findIndex((image) => image.src === src);
    this.lightbox.open(this.imageAlbum, index);
  }

  public close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  public clearAndSearch(): void {
    this.isDirty = true;
    this.targetFilterBy.value = "";
    this.searchProducts();
  }

  public async buildImages(): Promise<void> {
    // Add the UPC image to the album
    if (this.selectedProduct.UPC) {
      const albumItem = {
        src: this.getBarcodePreviewUrl(
          this.selectedProduct.UPC.substr(1),
          "UPC"
        ),
        caption: "UPC: " + this.selectedProduct.UPC,
        thumb: this.getBarcodeThumbnailUrl(
          this.selectedProduct.UPC.substr(1),
          "UPC"
        ),
      };
      this.imageAlbum.push(albumItem);
    }
    // Add the UPCE image to the album
    if (
      this.selectedProduct.UPC &&
      Helpers.supportsUPCE(this.selectedProduct.UPC)
    ) {
      const albumItem = {
        src: this.getBarcodePreviewUrl(
          this.selectedProduct.UPC.substr(1),
          "UPCE"
        ),
        caption:
          "UPC-E: " + Helpers.convertUPCAtoUPCE(this.selectedProduct.UPC),
        thumb: this.getBarcodeThumbnailUrl(
          this.selectedProduct.UPC.substr(1),
          "UPCE"
        ),
      };
      this.imageAlbum.push(albumItem);
    }
    // Add the carrier_UPC image to the album
    if (this.selectedProduct["carrierUPC"]) {
      const albumItem = {
        src: this.getBarcodePreviewUrl(
          this.selectedProduct["carrierUPC"].substr(1),
          "UPC"
        ),
        caption: "Carrier UPC: " + this.selectedProduct["carrierUPC"],
        thumb: this.getBarcodeThumbnailUrl(
          this.selectedProduct["carrierUPC"].substr(1),
          "UPC"
        ),
      };
      this.imageAlbum.push(albumItem);
    }
    // // Add the EAN image to the album
    // if (this.selectedProduct.EAN) {
    //   const albumItem = {
    //     src: this.getBarcodePreviewUrl(this.selectedProduct.EAN, 'UPC'),
    //     caption: 'EAN: ' + this.selectedProduct.EAN,
    //     thumb: this.getBarcodeThumbnailUrl(this.selectedProduct.EAN, 'UPC')
    //   };
    //   this.imageAlbum.push(albumItem);
    // }

    // if (!this.stateManagementService.isPrd()) {
    const cacheLookupBySku = await this.restService.get(
      Helpers.convertExportUrl(`cache/${this.selectedProduct.sku}`)
    );
    //   let findOnesWeWant = cacheLookupBySku.filter(type => {
    //     return type?.relatedpaths?.AssetTypeToAsset[0].some(item => item.values['en-US'] === 'Packaging' || item.values['en-US'] === 'Bottle' || item.values['en-US'] === 'Bottle Shot' || item.values['en-US'] === 'Tasting Note');
    //   });
    if (cacheLookupBySku && cacheLookupBySku.length) {
      const cacheLookupSKUImageObjects = cacheLookupBySku.map((image) =>
        this.buildImageObject(image)
      );
      this.cacheLookup = cacheLookupSKUImageObjects;
    } else {
      const cacheLookupByUpc = await this.restService.get(
        Helpers.convertExportUrl(`cache/upc/${this.selectedProduct.UPC}`)
      );
      // findOnesWeWant = cacheLookupByUpc.filter(type => {
      //   return type?.relatedpaths?.AssetTypeToAsset[0].some(item => item.values['en-US'] === 'Packaging' || item.values['en-US'] === 'Bottle' || item.values['en-US'] === 'Bottle Shot' || item.values['en-US'] === 'Tasting Note');
      // });
      const cacheLookupUPCImageObject = cacheLookupByUpc.map((image) =>
        this.buildImageObject(image)
      );
      this.cacheLookup = cacheLookupUPCImageObject;
    }
    // }

    this.imageAlbum.push(
      ...this.cacheLookup.map((image) => this.buildAlbum(image))
    );
  }

  public async selectProduct(product: any): Promise<void> {
    this.selectedProduct = undefined;
    // Resetting arrays to prevent the previous data from being seen when a different product is selected
    this.cacheLookup = [];
    this.imageAlbum = [];
    try {
      product["isLoading"] = true;
      this.isLoadingProduct = true;
      this.selectedProduct = await this.getProduct(product.sku);
      this.buildImages();
    } catch (error) {
      console.log("ERROR - selectProduct(): ", error);
    }

    product["isLoading"] = false;
    this.isLoadingProduct = false;

    this.pageLoading = false;
  }

  public trimNumber(value: string, toFixedVal: number): any {
    return Number(value).toFixed(toFixedVal);
  }

  public async exportList(
    type: "pdf" | "csv" | "excel" | "imagecsv"
  ): Promise<void> {
    this.isExporting = true;

    const payload = {
      params: this.buildGetProductParams(true),
    };

    try {
      if (type === "pdf") {
        // Not setup to with the export list
        const pdf = await this.restService.get(
          Helpers.convertExportUrl("product", this.selectedProduct.sku)
        );
        this.downloadFile(pdf, "export.pdf");
      } else if (type === "csv") {
        const csv = await this.restService.get(
          Helpers.convertExportUrl("exportlist", "csv"),
          payload
        );

        this.downloadFile(csv, "export.csv");
      } else if (type === "imagecsv") {
        try {
          const csv = await this.restService.get(
            Helpers.convertExportUrl("cache", this.selectedProduct.sku),
            payload
          );
          this.downloadFile(csv, "export.csv");
        } catch (error) {
          try {
            const csv = await this.restService.get(
              Helpers.convertExportUrl("cache", this.selectedProduct.UPC),
              payload
            );
            this.downloadFile(csv, "export.csv");
          } catch (error) {}
        }
      } else if (type === "excel") {
        const csv = await this.restService.get(
          Helpers.convertExportUrl("exportlist", "excel"),
          payload
        );

        this.downloadFile(csv, "export.xlsx");
      }
    } catch (error) {
      console.log(error);
      alert(`Error exporting ${type}`);
    }
    this.isExporting = false;
  }

  public async exportAsPDF(): Promise<void> {
    try {
      this.isExporting = true;
      const pdf = await this.restService.post(Helpers.convertExportUrl("pdf"), {
        product: this.selectedProduct,
      });
      this.downloadFile(pdf, this.selectedProduct.SKU + ".pdf");
      this.isExporting = false;
    } catch (error) {
      this.isExporting = false;
      alert(`Error exporting pdf`);
    }
  }

  public getBarcodeDownloadUrl(upc: string, format: string): string {
    return this.getBarcodeUrl(upc, format, "4", "200") + "&download=true";
  }

  public getBarcodeThumbnailUrl(upc: string, format: string): string {
    return this.getBarcodeUrl(upc, format);
  }

  public getBarcodePreviewUrl(upc: string, format: string): string {
    return this.getBarcodeUrl(upc, format, "4", "200");
  }

  public convertUPCAtoUPCE(upc: string): string {
    return Helpers.convertUPCAtoUPCE(upc);
  }

  public supportsUPCE(upc: string): boolean {
    return Helpers.supportsUPCE(upc);
  }

  public shouldShowStackLimit(uom: string): boolean {
    uom = uom.toUpperCase();
    return uom !== "EA" && uom !== "CS" && uom !== "LA";
  }

  public getStackLimitLabel(uom: string): string {
    switch (uom.toUpperCase()) {
      case "LT":
        return "Layers";
      case "LO":
        return "Layers";
      case "LW":
        return "Layers";
      case "HS":
        return "Pallets";
      default:
        return "";
    }
  }

  public async getUserType(accessToken: string) {
    const response = await this.restService.get({
      method: "GET",
      url: "https://graph.microsoft.com/v1.0/me?$select=country,employeeId",
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  }

  private buildImageObject(image) {
    return {
      ...image,
      gchassetid: image.gchassetid,
      caption: this.selectedProduct.productName,
      thumb:
        image.renditions &&
        image.renditions.thumbnail &&
        image.renditions.thumbnail[0]
          ? image.renditions.thumbnail[0].href
          : "",
      downloadPreview: `${this.stateManagementService.externalServiceUrl}&assetId=${image.gchassetid}&preferredRendition=downloadPreview`,
      downloadOriginal: `${this.stateManagementService.externalServiceUrl}&assetId=${image.gchassetid}&preferredRendition=downloadOriginal`,
    };
  }

  private buildAlbum(image) {
    return {
      caption: image.fileName,
      src: `${this.stateManagementService.externalServiceUrl}&assetId=${image.gchassetid}&preferredRendition=downloadPreview`,
    };
  }

  private downloadFile(file: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(file);

    this.downloadAnchorTag.download = fileName;
    this.downloadAnchorTag.href = url;

    this.downloadAnchorTag.click();

    window.URL.revokeObjectURL(url);
  }

  private async getProduct(productSKU: string): Promise<any> {
    const params: any = {
      id: productSKU,
    };
    if (this.stateManagementService.selectedDistributor) {
      params.dist = +this.stateManagementService.selectedDistributor.id;
    }
    return this.productApiService.getProduct(params).toPromise();
  }

  private rewriteUrl(): void {
    const queryParams = this.buildGetProductParams(true);
    if (this.stateManagementService.selectedDistributor)
      queryParams["distributor"] = MainComponent.obfuscate(
        JSON.stringify(this.stateManagementService.selectedDistributor)
      );
    this.router.navigate([""], { queryParams });
  }

  private setStateFromQueryParams(queryParams: any): void {
    Object.keys(queryParams).forEach((key) => {
      const param = queryParams[key];
      if (["skuid", "upcid", "distskuid"].includes(key)) {
        this.targetFilterBy.value = param;
        this.targetFilterBy.key = key as ETargetFilterBy;
      } else if (key === "distributor") {
        this.stateManagementService.selectedDistributor = JSON.parse(
          MainComponent.deobfuscate(param)
        );
      } else if (key === "destination") {
        this.regionalFilter = param;
      } else {
        this[key] = param;
      }
    });
    // We don't know when/where the 'destination' param is in the url, so we set this after the have all been dealt with
    this.showRegionalFilter = !(
      this.stateManagementService.selectedDistributor &&
      this.stateManagementService.selectedDistributor.id
    );
  }

  private buildGetProductParams(omitPaging: boolean = false) {
    const params = {} as ProductApiService.GetProductsParams;
    if (!omitPaging) {
      // These will always be defined
      params.pageno = this.pageno;
      params.pagesize = this.pagesize;
    }
    if (
      this.stateManagementService.selectedDistributor &&
      this.stateManagementService.selectedDistributor.id
    )
      params.dist = +this.stateManagementService.selectedDistributor.id;
    if (this.brandid) params.brandid = this.brandid;
    if (this.flavorid) params.flavorid = this.flavorid;
    if (this.sizeid) params.sizeid = this.sizeid;
    if (this.appellationid) params.appellationid = this.appellationid;
    if (this.targetFilterBy.value)
      params[this.targetFilterBy.key] = this.targetFilterBy.value;
    params.status = this.status;
    // We aren't applying the regionalFilter if it is hidden/disabled
    if (this.regionalFilter && this.showRegionalFilter)
      params.destination = this.regionalFilter;
    return params;
  }

  private getBarcodeUrl(
    upc: string,
    format: string,
    width?: string,
    height?: string
  ): string {
    let upcNumber = upc;
    if (format === "UPCE") {
      upcNumber = this.convertUPCAtoUPCE(upcNumber);
    }
    let serviceUrl = Helpers.convertExportUrl(
      "barcode/" + upcNumber + "?cache=1"
    );
    if (format) {
      serviceUrl = serviceUrl + "&format=" + format;
    }
    if (width) {
      serviceUrl = serviceUrl + "&width=" + width;
    }
    if (height) {
      serviceUrl = serviceUrl + "&height=" + height;
    }
    return serviceUrl;
  }

  // region Product Filters Loading/Filtering

  /**
   * Loads filters with optional selected distributor ID
   */
  private async loadFilters(): Promise<void> {
    this.isLoadingFilterList = true;
    try {
      if (this.showRegionalFilter) {
        this.filterList = await this.productApiService
          .getFilters({ destination: this.regionalFilter })
          .toPromise();
      } else {
        this.filterList = await this.productApiService
          .getFilters({
            dist: +this.stateManagementService.selectedDistributor.id,
          })
          .toPromise();
      }
    } catch (error) {
      console.log("ERROR - loadFilters(): ", error);
      this.filterList = [];
    }

    this.filterFilters();
    this.isLoadingFilterList = false;
  }

  private filterFilters(): void {
    const brands = this.filterList.filter((f) =>
      this.getFilterMatch("brand", f)
    );
    this.brands = this.getUniqueFilters("brandid", "brand", brands);
    this.brands = this.brands.sort(this.sortFilterOption);

    const flavors = this.filterList.filter((f) =>
      this.getFilterMatch("flavor", f)
    );
    this.flavors = this.getUniqueFilters("flavorid", "flavor", flavors);
    this.flavors = this.flavors.sort(this.sortFilterOption);

    const sizes = this.filterList.filter((f) => this.getFilterMatch("size", f));
    this.sizes = this.getUniqueFilters("sizeid", "size", sizes);
    this.sizes = this.sortSizes(this.sizes);

    const appellations = this.filterList.filter((f) =>
      this.getFilterMatch("appellation", f)
    );
    this.appellations = this.getUniqueFilters(
      "appellationid",
      "appellation",
      appellations
    );
    this.appellations = this.appellations.sort(this.sortFilterOption);
  }

  private getFilterMatch(exclude: string, filter: any): boolean {
    let matchFilter = true;
    if (exclude !== "brand" && this.brandid) {
      matchFilter = matchFilter && this.brandid === filter.brandid;
    }
    if (exclude !== "flavor" && this.flavorid) {
      matchFilter = matchFilter && this.flavorid === filter.flavorid;
    }

    if (exclude !== "size" && this.sizeid) {
      matchFilter = matchFilter && this.sizeid === filter.sizeid;
    }

    if (exclude !== "appellation" && this.appellationid) {
      matchFilter = matchFilter && this.appellationid === filter.appellationid;
    }

    return matchFilter;
  }

  private getUniqueFilters(
    filterKey: string,
    filterName: string,
    source: any[]
  ): IFilterOption[] {
    const filters = source.reduce((acc, filter) => {
      if (filter[filterKey] && filter[filterName]) {
        acc[filter[filterKey]] = filter[filterName];
      }
      return acc;
    }, {});
    return Object.keys(filters).map((key) => {
      return { key, name: filters[key] };
    });
  }

  private sortFilterOption(a: IFilterOption, b: IFilterOption): number {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }

  /**
   * Sorts the sizes by their value in ML
   * @param sizes Sizes to be sorted
   */
  private sortSizes(sizes: IFilterOption[]): IFilterOption[] {
    const volumeConversion = (size: IFilterOption) => {
      const match = size.name.match(/[\d+|[\d+.[\d+]+/);

      // INC1141564 / INC1144577- Data Gov made KEG, MATCH, and PENDING available to be used for all fields for items not yet active.
      if (match === null) {
        // console.log("debug------------size.name" + size.name);
        return parseFloat("99999");
      }

      const volume = parseFloat(match[0]);
      if (size.name.includes("ML")) {
        return volume;
      } else if (
        size.name.includes("L") &&
        !size.name.includes("ML") &&
        !size.name.includes("GBRL")
      ) {
        return volume * 1000;
      } else if (size.name.includes("OZ")) {
        return volume * 29.5735;
      } else if (size.name.includes("GBRL")) {
        return volume * 3785.41;
      } else {
        return volume;
      }
    };

    return sizes.sort((a, b) => {
      const aML = volumeConversion(a);
      const bML = volumeConversion(b);
      return aML - bML;
    });
  }

  // endregion
}
